import React, { useContext, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { Formik } from "formik";
import * as Yup from "yup";
import AuthService from "../services/auth-service";
import { useNavigate } from "react-router-dom";

import { AuthDispatchContext, signIn } from "../context/auth";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import cookies from "js-cookie";

import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";

const theme = createTheme();

export default function SignInSide() {
  let navigate = useNavigate();
  const currentLanguageCode = cookies.get("i18next") || "en";

  const { t } = useTranslation();
  const authDispatch = useContext(AuthDispatchContext);

  const signInSuccess = (userData) => {
    signIn(authDispatch, userData);
  };

  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "cn",
      name: "中文",
      country_code: "cn",
    },
  ];

  const gotoRegistration = () => {
    navigate("/register");
  };

  const rememberMe = localStorage.getItem("rememberMe") === "true";
  const user = rememberMe ? localStorage.getItem("user") : "";

  useEffect(() => {
    loadCaptchaEnginge(4, "white", "black", "numbers");
  }, []);

  return (
    <Formik
      initialValues={{
        username: user,
        password: "",
        user_captcha_input: "",
        rbme: rememberMe,
      }}
      validationSchema={Yup.object({
        username: Yup.string().required(t("isRequired") + ":" + t("username")), //-- yck 26.03
        password: Yup.string().required(t("isRequired") + ":" + t("password")), //-- yck 26.03
        user_captcha_input: Yup.string().required(
          t("isRequired") + ":" + t("securityCode")
        ), //-- yck 26.03
      })}
      onSubmit={(values, { props, setFieldError, setSubmitting }) => {
        if (validateCaptcha(values.user_captcha_input)) {
          setTimeout(() => {
            AuthService.login(values.username, values.password)
              .then((res) => {
                localStorage.setItem("rememberMe", values.rbme);
                localStorage.setItem(
                  "user",
                  values.rbme ? values.username : ""
                );

                signInSuccess(res.data);
                setSubmitting(false);
                navigate("/");
              })
              .catch((e) => {
                if (!e?.response) {
                  setFieldError("username", t("networkError")); //-- yck 26.03
                  setSubmitting(false);
                } else if (e.response?.status === 404) {
                  setFieldError("username", t("userNotExist")); //-- yck 26.03
                  setSubmitting(false);
                } else if (e.response?.status === 403) {
                  setFieldError("password", t("loginFail")); //-- yck 26.03
                  setSubmitting(false);
                }
              });
          }, 300);
        } else {
          setFieldError(
            "user_captcha_input",
            t("invalid") + ":" + t("securityCode")
          ); //-- yck 26.03
          setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <ThemeProvider theme={theme}>
          <Grid container component="main" sx={{ height: "100vh" }}>
            <CssBaseline />
            <Grid
              item
              xs={false}
              sm={4}
              md={7}
              sx={{
                // backgroundImage: "url(https://source.unsplash.com/random)",
                // backgroundImage:
                //   "url(`${process.env.PUBLIC_URL}/assets/images/bg.jpg`)", //-- yck 26.03
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + "/assets/images/bg-7.webp"
                })`,
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                  t.palette.mode === "light"
                    ? t.palette.grey[50]
                    : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <Box
                position="absolute"
                width="100%"
                bottom={0}
                p={2}
                display={{ xs: "none", md: "block" }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <Link
                    href="https://www.freepik.com/free-photo/red-delivery-car-deliver-express-shipping-fast-delivery-background-3d-rendering-illustration_30133175.htm#query=delivery&position=3&from_view=search&track=sph&uuid=99659d8e-2b33-4ccd-b4f1-48b7b7ee5eae"
                    target="_blank"
                  >
                    <Typography fontSize="13px" color="#000">
                      Image by mamewmy on Freepik
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              component={Paper}
              elevation={6}
              square
            >
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "error.light" }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  {t("login_title")}
                </Typography>

                <Box sx={{ mt: 1 }}>
                  <form onSubmit={formik.handleSubmit}>
                    <TextField
                      margin="normal"
                      fullWidth
                      size="small"
                      id="username"
                      label={t("username")}
                      name="username"
                      autoFocus
                      {...formik.getFieldProps("username")}
                      error={
                        formik.touched.username &&
                        Boolean(formik.errors.username)
                      }
                      helperText={
                        formik.touched.username && formik.errors.username
                      }
                    />

                    <TextField
                      margin="normal"
                      fullWidth
                      size="small"
                      name="password"
                      label={t("password")} //-- yck 26.03
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      {...formik.getFieldProps("password")}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />

                    <Box sx={{ pt: 2, textAlign: "center" }}>
                      <LoadCanvasTemplate
                        reloadText={t("reload_code") + t("securityCode")} //-- yck 26.03
                        reloadColor="black"
                      />
                    </Box>

                    <TextField
                      margin="normal"
                      fullWidth
                      size="small"
                      name="user_captcha_input"
                      label={t("securityCode")} //-- yck 26.03
                      type="text"
                      id="user_captcha_input"
                      autoComplete="false"
                      {...formik.getFieldProps("user_captcha_input")}
                      error={
                        formik.touched.user_captcha_input &&
                        Boolean(formik.errors.user_captcha_input)
                      }
                      helperText={
                        formik.touched.user_captcha_input &&
                        formik.errors.user_captcha_input
                      }
                    />

                    <FormControlLabel
                      control={
                        <Checkbox color="error" checked={formik.values.rbme} />
                      }
                      label={t("remember_me")}
                      name="rbme"
                      value="Y"
                      onChange={formik.handleChange}
                    />

                    <Button
                      variant="contained"
                      color="error" //-- yck 26.03
                      type="submit"
                      fullWidth
                      sx={{ mt: 3, mb: 3 }}
                      disabled={formik.isSubmitting}
                    >
                      {formik.isSubmitting && (
                        <CircularProgress size={28} color="success" /> //-- yck 26.03
                      )}
                      {!formik.isSubmitting && t("login_title")}
                    </Button>

                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item sx={{ mt: 1 }}>
                        <Link
                          href="#"
                          variant="body2"
                          style={{ color: "#333" }}
                        >
                          {t("forgot_pwd")}
                        </Link>
                      </Grid>
                      <Grid item sx={{ mt: 1 }}>
                        <Link
                          href="#"
                          variant="body2"
                          style={{ color: "#333" }}
                          onClick={gotoRegistration}
                        >
                          {t("New Registration")}
                        </Link>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
                {/* <Select
                  size="small"
                  labelId="langueges"
                  id="langueges"
                  defaultValue={currentLanguageCode}
                >
                  {languages.map(({ code, name, country_code }) => (
                    <MenuItem
                      key={country_code}
                      value={code}
                      onClick={() => {
                        i18n.changeLanguage(code);
                        cookies.set("i18next", code);
                      }}
                      disabled={code === currentLanguageCode}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select> */}
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      )}
    </Formik>
  );
}
