const getLocalRefreshToken = () => {
  const user = JSON.parse(sessionStorage.getItem("currentUser"));
  return user?.refreshToken;
};

const getLocalAccessToken = () => {
  const user = JSON.parse(sessionStorage.getItem("currentUser"));
  return user?.token;
};

const updateLocalAccessToken = (token) => {
  let user = JSON.parse(sessionStorage.getItem("currentUser"));
  user.token = token;
  sessionStorage.setItem("currentUser", JSON.stringify(user));
};

const getUser = () => {
  return JSON.parse(sessionStorage.getItem("currentUser"));
};

const setUser = (user) => {
  sessionStorage.setItem("currentUser", JSON.stringify(user));
};

const removeUser = () => {
  sessionStorage.removeItem("currentUser");
};

const getSessionRoleID = () => {
  const user = JSON.parse(sessionStorage.getItem("currentUser"));
  return user?.roleid;
};

const getSessionUsername = () => {
  const user = JSON.parse(sessionStorage.getItem("currentUser"));
  return user?.user;
};

const getSessionUsrCtryId = () => {
  const user = JSON.parse(sessionStorage.getItem("currentUser"));
  return user?.usrctrid;
};

const getSessionCtryId = () => {
  const user = JSON.parse(sessionStorage.getItem("currentUser"));
  return user?.ctrid;
};

const getSessionBranchId = () => {
  const user = JSON.parse(sessionStorage.getItem("currentUser"));
  return user?.branchid;
};

const updateSessionUsrCtryInfo = (usrctrid, ctryid, roleid) => {
  let user = JSON.parse(sessionStorage.getItem("currentUser"));
  user.usrctrid = usrctrid;
  user.ctrid = ctryid;
  user.roleid = roleid;
  sessionStorage.setItem("currentUser", JSON.stringify(user));
};

const exportedObject = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  removeUser,
  getSessionRoleID,
  getSessionUsername,
  getSessionUsrCtryId,
  updateSessionUsrCtryInfo,
  getSessionCtryId,
  getSessionBranchId,
};

export default exportedObject;
