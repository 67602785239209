/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */
import colors from "./base/colors";

// Material Dashboard 2 PRO React Helper Functions
import pxToRem from "./functions/pxToRem";

export default function themeTypography(theme) {
    const { dark } = colors;

    const baseProperties = {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeightLighter: 100,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        fontWeightBold: 700,
        fontSizeXXS: pxToRem(10.4),
        fontSizeXS: pxToRem(12),
        fontSizeSM: pxToRem(14),
        fontSizeMD: pxToRem(16),
        fontSizeLG: pxToRem(18),
        fontSizeXL: pxToRem(20),
        fontSize2XL: pxToRem(24),
        fontSize3XL: pxToRem(30),
    };

    const baseHeadingProperties = {
        fontFamily: baseProperties.fontFamily,
        color: dark.main,
        fontWeight: baseProperties.fontWeightBold,
    };

    const baseDisplayProperties = {
        fontFamily: baseProperties.fontFamily,
        color: dark.main,
        fontWeight: baseProperties.fontWeightLight,
        lineHeight: 1.2,
    };

    return {
        fontFamily: theme?.customization?.fontFamily,
        fontWeightLighter: baseProperties.fontWeightLighter,
        fontWeightLight: baseProperties.fontWeightLight,
        fontWeightRegular: baseProperties.fontWeightRegular,
        fontWeightMedium: baseProperties.fontWeightMedium,
        fontWeightBold: baseProperties.fontWeightBold,

        h6: {
            fontWeight: 500,
            color: theme.heading,
            fontSize: '0.75rem'
        },
        h5: {
            fontSize: '0.875rem',
            color: theme.heading,
            fontWeight: 500
        },
        h4: {
            fontSize: '1rem',
            color: theme.heading,
            fontWeight: 600
        },
        h3: {
            fontSize: '1.25rem',
            color: theme.heading,
            fontWeight: 600
        },
        h2: {
            fontSize: '1.5rem',
            color: theme.heading,
            fontWeight: 700
        },
        h1: {
            fontSize: '2.125rem',
            color: theme.heading,
            fontWeight: 700
        },
        subtitle1: {
            fontSize: '0.875rem',
            fontWeight: 500,
            color: theme.textDark
        },
        subtitle2: {
            fontSize: '0.75rem',
            fontWeight: 400,
            color: theme.darkTextSecondary
        },
        // caption: {
        //     fontSize: '0.75rem',
        //     color: theme.darkTextSecondary,
        //     fontWeight: 400
        // },
        body1: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.334em'
        },
        body2: {
            letterSpacing: '0em',
            fontWeight: 400,
            lineHeight: '1.5em',
            color: theme.darkTextPrimary
        },
        button: {
            // fontFamily: baseProperties.fontFamily,
            fontSize: baseProperties.fontSizeSM,
            fontWeight: baseProperties.fontWeightLight,
            lineHeight: 1.5,
            textTransform: 'capitalize'
        },
        caption: {
            fontFamily: baseProperties.fontFamily,
            fontSize: baseProperties.fontSizeXS,
            fontWeight: baseProperties.fontWeightLight,
            lineHeight: 1.25,
            color: theme.darkTextSecondary,
        },

        overline: {
            fontFamily: baseProperties.fontFamily,
        },

        d1: {
            fontSize: pxToRem(80),
            ...baseDisplayProperties,
        },

        d2: {
            fontSize: pxToRem(72),
            ...baseDisplayProperties,
        },

        d3: {
            fontSize: pxToRem(64),
            ...baseDisplayProperties,
        },

        d4: {
            fontSize: pxToRem(56),
            ...baseDisplayProperties,
        },

        d5: {
            fontSize: pxToRem(48),
            ...baseDisplayProperties,
        },

        d6: {
            fontSize: pxToRem(40),
            ...baseDisplayProperties,
        },

        size: {
            xxs: baseProperties.fontSizeXXS,
            xs: baseProperties.fontSizeXS,
            sm: baseProperties.fontSizeSM,
            md: baseProperties.fontSizeMD,
            lg: baseProperties.fontSizeLG,
            xl: baseProperties.fontSizeXL,
            "2xl": baseProperties.fontSize2XL,
            "3xl": baseProperties.fontSize3XL,
        },

        lineHeight: {
            sm: 1.25,
            md: 1.5,
            lg: 2,
        },
        customInput: {
            marginTop: 1,
            marginBottom: 1,
            '& > label': {
                top: 23,
                left: 0,
                color: theme.grey500,
                '&[data-shrink="false"]': {
                    top: 5
                }
            },
            '& > div > input': {
                padding: '30.5px 14px 11.5px !important'
            },
            '& legend': {
                display: 'none'
            },
            '& fieldset': {
                top: 0
            }
        },
        mainContent: {
            backgroundColor: theme.background,
            width: '100%',
            minHeight: 'calc(100vh - 88px)',
            flexGrow: 1,
            padding: '20px',
            marginTop: '88px',
            marginRight: '20px',
            borderRadius: `12px`
        },
        menuCaption: {
            fontSize: '0.875rem',
            fontWeight: 600,
            color: theme.heading,
            padding: '6px',
            textTransform: 'capitalize',
            marginTop: '10px'
        },
        subMenuCaption: {
            fontSize: '0.6875rem',
            fontWeight: 500,
            color: theme.darkTextSecondary,
            textTransform: 'capitalize'
        },
        commonAvatar: {
            cursor: 'pointer',
            borderRadius: '8px'
        },
        smallAvatar: {
            width: '22px',
            height: '22px',
            fontSize: '1rem'
        },
        mediumAvatar: {
            width: '34px',
            height: '34px',
            fontSize: '1.2rem'
        },
        largeAvatar: {
            width: '44px',
            height: '44px',
            fontSize: '1.5rem'
        }
    };
}
