import React, { useReducer, createContext, useEffect, useState} from "react";
// import _get from "lodash.get";


let user = sessionStorage.getItem('currentUser')
	? JSON.parse(sessionStorage.getItem('currentUser')).user
	: '';
let token = sessionStorage.getItem('currentUser')
	? JSON.parse(sessionStorage.getItem('currentUser')).auth_token
	: '';

const initialState = {
  isLoggedIn: user ? true : false,
  user: '' || user,
	token: '' || token,
  isLoggingIn: false
};

export const AuthStateContext = createContext();
export const AuthDispatchContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_REQUEST":
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        isLoggingIn: true
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload.user,
        token: action.payload.token,
        isLoggingIn: false
      };
    case "LOGIN_FAILURE":
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        isLoggingIn: false
      };
    case "LOGOUT_SUCCESS":
      return {
        ...state,
        ...initialState
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const signIn = (dispatch, userData) => {
 
    if (userData.user) {
			dispatch({ type: 'LOGIN_SUCCESS', payload: userData });
			sessionStorage.setItem('currentUser', JSON.stringify(userData));
			return userData;
		}

		dispatch({ type: 'LOGIN_FAILURE', error: userData.errors[0] });
	
		return;
};

export const signOut = (dispatch) => {

  sessionStorage.clear();
  return dispatch({
    type: "LOGOUT_SUCCESS"
  });
};

const AuthProvider = ({ children }) => {
  //const [persistedUser, setPersistedUser] = useLocalStorage("user", null);
   const [persistedUser, setPersistedUser] = useState(sessionStorage.getItem("currentUser"));

  // const persistedUserState = {
  //    ...initialState,
  //    user: persistedUser,
  //    isLoggedIn: _get(persistedUser, "currentUser", "").length > 0
  // };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    setPersistedUser(state.user);
  }, [state.isLoggedIn]);
 
  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthStateContext.Provider value={state}>
        {children}
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
};

export default AuthProvider;
