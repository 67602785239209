import React, { useReducer, createContext } from "react";

const initialState = {
  isSideBarOpen: true,
  drawerWidth : 260,
  gridSpacing : 3
};

export const CommonStateContext = createContext();
export const CommonDispatchContext = createContext();

const reducer = (state, action) => {
  
  switch (action.type) {
    case "TOGGLE_SIDE_BAR":
      return {
        ...state,
        isSideBarOpen: !state.isSideBarOpen
      };
    case "CHANGE_PAGE_TITLE":
      return {
        ...state,
        pageTitle: action.payload.ptitle,
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

const CommonProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CommonDispatchContext.Provider value={dispatch}>
      <CommonStateContext.Provider value={state}>
        {children}
      </CommonStateContext.Provider>
    </CommonDispatchContext.Provider>
  );
};

export const toggleSideBar = (dispatch) => {
  return dispatch({
    type: "TOGGLE_SIDE_BAR"
  });
};

export const setSearchKeyword = (dispatch, searchKeyword) => {
  return dispatch({
    type: "SET_SEARCH_KEYWORD",
    payload: {
      searchKeyword: searchKeyword
    }
  });
};

export const setPageTitle = (dispatch, pageData) => {
    return dispatch({ 
      type: 'CHANGE_PAGE_TITLE', 
      payload: {
        ptitle : pageData.title
      }
    });
};

export default CommonProvider;
