/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

export default function themePalette(theme) {
  return {
    mode: theme?.customization?.navType,
    common: {
      black: theme.colors?.darkPaper,
    },
    primary: {
      light: theme.colors?.primaryLight,
      main: theme.colors?.primaryMain,
      dark: theme.colors?.primaryDark,
      200: theme.colors?.primary200,
      800: theme.colors?.primary800,
    },
    secondary: {
      light: theme.colors?.secondaryLight,
      main: theme.colors?.secondaryMain,
      dark: theme.colors?.secondaryDark,
      200: theme.colors?.secondary200,
      400: theme.colors?.secondary400,
      800: theme.colors?.secondary800,
    },
    error: {
      light: theme.colors?.errorLight,
      main: theme.colors?.errorMain,
      dark: theme.colors?.errorDark,
    },
    orange: {
      light: theme.colors?.orangeLight,
      main: theme.colors?.orangeMain,
      dark: theme.colors?.orangeDark,
    },
    warning: {
      light: theme.colors?.warningLight,
      main: theme.colors?.warningMain,
      dark: theme.colors?.warningDark,
    },
    success: {
      light: theme.colors?.successLight,
      200: theme.colors?.success200,
      main: theme.colors?.successMain,
      dark: theme.colors?.successDark,
    },
    grey: {
      50: theme.colors?.grey50,
      100: theme.colors?.grey100,
      500: theme.darkTextSecondary,
      600: theme.heading,
      700: theme.darkTextPrimary,
      900: theme.textDark,
    },
    dark: {
      light: theme.colors?.darkTextPrimary,
      main: theme.colors?.darkLevel1,
      dark: theme.colors?.darkLevel2,
      800: theme.colors?.darkBackground,
      900: theme.colors?.darkPaper,
    },
    text: {
      primary: theme.darkTextPrimary,
      secondary: theme.darkTextSecondary,
      dark: theme.textDark,
      hint: theme.colors?.grey100,
      main: "#7b809a",
      focus: "#7b809a",
    },
    background: {
      paper: theme.paper,
      default: theme.backgroundDefault,
    },
    transparent: {
      main: "transparent",
    },
    white: {
      main: "#ffffff",
      focus: "#ffffff",
    },
    black: {
      light: "#000000",
      main: "#000000",
      focus: "#000000",
    },
    light: {
      main: "#e3e4e6",
      focus: "#e3e4e6",
    },
    lightblue: {
      main: "#e3f2fd",
      focus: "#cce8fd",
    },
    gradients: {
      primary: {
        main: "#EC407A",
        state: "#D81B60",
      },

      secondary: {
        main: "#747b8a",
        state: "#495361",
      },

      info: {
        main: "#49a3f1",
        state: "#1A73E8",
      },

      success: {
        main: "#66BB6A",
        state: "#43A047",
      },

      warning: {
        main: "#FFA726",
        state: "#FB8C00",
      },

      error: {
        main: "#EF5350",
        state: "#E53935",
      },

      light: {
        main: "#EBEFF4",
        state: "#CED4DA",
      },

      dark: {
        main: "#42424a",
        state: "#191919",
      },

      lightblue: {
        main: "#EBEFF4",
        state: "#CED4DA",
      },
    },
  };
}
