import axios from "axios";
import TokenService from "./services/token.service";

const instance = axios.create({
  baseURL: "https://myparcel2u.com.my/skycust",
  // baseURL: "https://myparcel2u.com.my/skycust_dev",
  // baseURL: "http://localhost:81/skycust",
  headers: {
    "Content-type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();

    if (token) {
      config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
      //config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/auth" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        // sessionStorage.removeItem("currentUser");
        // window.location = `${process.env.PUBLIC_URL}/auth`;
        originalConfig._retry = true;
        try {
          //const rs = await instance.post("/auth/refresh_token", {refreshToken: TokenService.getLocalRefreshToken(),});
          const { user } = TokenService.getUser();
          const rs = await instance.post(
            "/auth/refresh_token",
            JSON.stringify({ user })
          );
          const { token } = rs.data;

          TokenService.updateLocalAccessToken(token);
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);

export default instance;
